import React from 'react';
import './styles/Contact.css';

function Contact() {
  return (
    <section className="contact" id="contact">
      <h2>Get in Touch!</h2>
      <p>Send me an email to know more about me or discuss opportunities. </p>
      <a href="mailto:adiba.akter.98@gmail.com" className="email-button">Email Me</a>
      <p>Phone: (916) 743-8234</p>
      <p className="copyright">© 2024 All rights reserved. Designed and developed by Adiba Akter.</p>
    </section>
  );
}

export default Contact;
